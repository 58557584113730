<template>
  <div>
    <!-- <div class="d-flex justify-content-start align-items-center my-10">
        Toplam {{ totalItems }} müşteri bulundu.
      </div> -->
    <div
      class="d-flex align-items-center justify-content-between search-list-area"
    >
      <div class="d-flex align-items-center search-input mb-4">
        <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
        <select v-model="list_length" class="form-control ml-2">
          <option value="" selected>Seçiniz</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
      <div class="mb-4">
        <b-button variant="primary" :to="'/customer/add/'"
          >Müşteri Ekle</b-button
        >
        <b-button
          variant="outline-primary"
          class="ml-4"
          v-b-toggle.sidebar-right
          >Filtrele</b-button
        >
      </div>
    </div>

    <div class="">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Varlık Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.id="{ item }">
          <router-link :to="`/customer/detail/${item.id}/`">
            <button class="btn btn-sm btn-light">
              <i class="flaticon2-next text-dark p-0"></i>
            </button>
          </router-link>
        </template>

        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex px-4">
            <router-link
              :to="`/transfer/edit`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="fas fa-trash"></i
              ></b-button>
            </router-link>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
    <div class="notes-sidebar top-full">
      <b-sidebar id="sidebar-right" right>
        <div
          class="card dveb-gray-card aside-gray-card tab-card"
          style="overflow-y: auto"
        >
          <div class="card-header">FİLTRELE</div>
          <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="Filtre" active>
              <div class="form-group">
                <label for="sahip">Sahibi</label>
                <select name="" id="sahip" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Şekerbank A.Ş</option>
                </select>
              </div>
              <div class="form-group">
                <label for="izle">İzlenecek</label>
                <select name="" id="izle" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Evet</option>
                  <option value="">Hayır</option>
                </select>
              </div>
              <div class="form-group">
                <label for="durum">Durum</label>
                <select name="" id="durum" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="il">İl</label>
                <select name="" id="il" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="ilce">İlçe</label>
                <select name="" id="ilce" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="anahat">Anahtar Durumu</label>
                <select name="" id="anahat" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Var</option>
                  <option value="">Yok</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="anahat" class="form-label">İşgalci Durumu</label>
                <select name="" id="anahat" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Var</option>
                  <option value="">Yok</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="anahat">Ada</label>
                <input type="text" class="form-control" />
              </div>
              <div class="form-group">
                <label for="anahat">Parsel</label>
                <input type="text" class="form-control" />
              </div>
              <div class="row">
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Temizle </b-button>
                </div>
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Uygula </b-button>
                </div>
                <div class="col-12 mt-4">
                  <b-button variant="light" class="w-100">
                    Bu Filtreyi Kaydet
                  </b-button>
                </div>
              </div>
            </b-tab>
            <b-tab title="Hızlı Ara">
              <div
                class="card mt-4 dveb-card-white"
                style="border-radius: 0 !important"
              >
                <div>
                  <h4>Filtre Örnek</h4>
                  <hr />
                  <div class="row ml-auto">
                    <b-button variant="success" class="mr-4"> Uygula </b-button>
                    <b-button
                      variant="danger"
                      class=""
                      @click="openDeleteHandler(key)"
                    >
                      Sil
                    </b-button>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-sidebar>
    </div>
    <DeleteOperation />
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
</style>

<script>
import Vue from 'vue'   
import axios from 'axios'
import VueAxios from 'vue-axios'

export default {
  name: "customerList",

  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames: ["Hazırlık", "Satış", "Devir"],
      header: [
        { text: "", value: "id", size: "50px" },
        { text: "Ad Soyad", sortable: true, value: "name" },
        { text: "E-Posta", sortable: true, value: "email" },
        { text: "Doğum Tarihi", sortable: true, value: "birthday" },
        { text: "TCKN", value: "TCKN" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList: [],
      list_length: 5,
    };
  },
  mounted() {
    fetch(
      "http://sunucu.dveb.com.tr:9090/api/v1/sell/property/getAllProperties",{
        headers:{
        'Content-type': 'application/json',
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGMyNWZjZTg3MmJiZDI2ZWQ0YmQ3MmQiLCJlbWFpbCI6InRhcmlrQGR2ZWIubmV0IiwiaWF0IjoxNjkwNDYzNjY4LCJleHAiOjE2OTMwNTU2Njh9.a0i8hi3bq2PFk7IXT6-ODLRJ5DSuvZecXec7ZiFEcZI`, // notice the Bearer before your token
      }
      }
    
    ).then((response) => response.json()).then(data => console.log(data));
  },
  created() {
    this.search = this.$route.query.search;
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.items = [
        {
          id: 1,
          name: "Rasim",
          surname: "Karadeniz",
          email: "rasimkaradeniz5757@hotmail.com",
          birthday: "04.02.1998",
          TCKN: "12345678910",
        },
      ];
      this.totalItems = this.items.length;

      this.pagination = {
        current_page: 1,
        total_pages: Math.ceil(this.items?.length / this.list_length),
        total_items: this.items.length,
        list_length: this.list_length,
      };
      this.loading = false;
      if (this.search) {
        const searchValue = this.search;
        const foundItems = this.items.filter((item) => {
          for (const prop in item) {
            if (item[prop].toString().toLowerCase().includes(searchValue)) {
              return true;
            }
          }
          return false;
        });
        this.items = foundItems;
      }

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show("modal-deleteOperation");
      let index = this.notes.indexOf(key);
      this.notes.splice(index, 1);
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
    list_length: function () {
      this.getList();
    },
  },
};
</script>
